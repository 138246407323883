import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import "swiper/css/bundle";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/thumbs";

// import required modules
import { Autoplay, Parallax, EffectFade, Pagination, Thumbs } from "swiper";

import Layout from "../components/layout"
import Seo from "../components/seo";
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "1920x1080/img1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      image2: file(relativePath: { eq: "1920x1080/img2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      image3: file(relativePath: { eq: "1920x1080/img3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  // https://www.gatsbyjs.com/plugins/gatsby-background-image/#gatsby-34--gatsby-plugin-image
  // Watch out for CSS's stacking order, especially when styling the individual
  // positions! The lowermost image comes last!
  // const backgroundFluidImageStack = [
  //   img3.childImageSharp.fluid,
  //   `linear-gradient(rgba(220, 15, 15, 0.73), rgba(4, 243, 67, 0.73))`,
  //   img2.childImageSharp.fluid,
  // ].reverse()




  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <Layout>
      <Seo title={"Home"} />
      {/* Hero breakpoints={580: {slidesPerView: 3, spaceBetween: 30}, 1024: {slidesPerView: 4, spaceBetween: 50}} */}
      <div className="position-relative">
        <Swiper
          autoplay={true}
          loop={true}
          speed={6000}
          parallax={true}
          pagination={{
            el: '.js-swiper-blog-modern-hero-pagination',
            clickable: true,
          }}
          modules={[Autoplay, Parallax, Pagination, Thumbs]}
          className="js-swiper-blog-modern-hero swiper-equal-height"
          thumbs={{ swiper: thumbsSwiper }}
        >
          <div
            slot="container-start"
            className="parallax-bg"
            data-swiper-parallax="-23%"
          ></div>

          <SwiperSlide>
            <BackgroundImage
              Tag="div"
              className={"bg-img-start content-space-2 content-space-t-sm-3 content-space-b-sm-4"}
              fluid={data.image1.childImageSharp.fluid}
            >
              {/*  Container  */}
              <div className="container content-space-b-sm-2">
                <div className="row">
                  <div className="col-md-6">
                    {/*  Media  */}
                    <div className="d-flex align-items-center mb-3">
                      <div className="flex-shrink-0">
                        <div className="avatar avatar-circle">
                          <Img fluid={data.image1.childImageSharp.fluid} className="avatar-img" alt="Image Description1" />
                        </div>
                      </div>

                      <div className="flex-grow-1 ms-3">
                        <a className="text-white" href="./blog-article.html">Louise Donadieu</a>
                      </div>
                    </div>
                    {/*  End Media  */}

                    <div className="mb-5">
                      <h2 className="h1 text-white">Unify Corporate - let's work together</h2>
                    </div>

                    <a className="btn btn-light btn-pointer" href="./blog-article.html">Read article</a>
                  </div>
                  {/*  End Col  */}
                </div>
                {/*  End Row  */}
              </div>
              {/*  End Container  */}
            </BackgroundImage>
          </SwiperSlide>
          <SwiperSlide>
            <BackgroundImage
              Tag="div"
              className={"bg-img-start content-space-2 content-space-t-sm-3 content-space-b-sm-4"}
              fluid={data.image2.childImageSharp.fluid}
            >
              {/*  Container  */}
              <div className="container content-space-b-sm-2">
                <div className="row">
                  <div className="col-md-6">
                    {/*  Media  */}
                    <div className="d-flex align-items-center mb-3">
                      <div className="flex-shrink-0">
                        <div className="avatar avatar-circle">
                          <Img fluid={data.image2.childImageSharp.fluid} className="avatar-img" alt="Image Description" />
                        </div>
                      </div>

                      <div className="flex-grow-1 ms-3">
                        <a className="text-white" href="./blog-article.html">Louise Donadieu</a>
                      </div>
                    </div>
                    {/*  End Media  */}

                    <div className="mb-5">
                      <h2 className="h1 text-white">How Google Assistant now helps you record stories</h2>
                    </div>

                    <a className="btn btn-light btn-pointer" href="./blog-article.html">Read article</a>
                  </div>
                  {/*  End Col  */}
                </div>
                {/*  End Row  */}
              </div>
              {/*  End Container  */}
              </BackgroundImage>
          </SwiperSlide>
          <SwiperSlide>
            <BackgroundImage
              Tag="div"
              className={"bg-img-start content-space-2 content-space-t-sm-3 content-space-b-sm-4"}
              fluid={data.image3.childImageSharp.fluid}
            >
              {/*  Container  */}
              <div className="container content-space-b-sm-2">
                <div className="row">
                  <div className="col-md-6">
                    {/*  Media  */}
                    <div className="d-flex align-items-center mb-3">
                      <div className="flex-shrink-0">
                        <div className="avatar avatar-circle">
                          <Img fluid={data.image1.childImageSharp.fluid} className="avatar-img" alt="Image3 Description" />
                        </div>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <a className="text-white" href="./blog-article.html">Jeff Fisher</a>
                      </div>
                    </div>
                    {/*  End Media  */}

                    <div className="mb-5">
                      <h2 className="h1 text-white">Unify Interview with a Lead Designer of the Hubble</h2>
                    </div>

                    <a className="btn btn-light btn-pointer" href="./blog-article.html">Read article</a>
                  </div>
                  {/*  End Col  */}
                </div>
                {/*  End Row  */}
              </div>
              {/*  End Container  */}
            </BackgroundImage>
          </SwiperSlide>
        </Swiper>
        {/* Swiper Thumbs Slider */}
        <div className="position-sm-absolute bottom-0 start-0 end-0 zi-2 d-none d-sm-block mb-7">
          <div className="container content-space-t-1">
            <Swiper
              modules={[Thumbs]}
              watchSlidesProgress
              onSwiper={setThumbsSwiper}
              className="js-swiper-blog-modern-hero-thumbs swiper-step-pagination swiper-step-pagination-light"
              slidesPerView={4}
              breakpoints={{
                580: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
              }}
            >
              <SwiperSlide>
                <span className="swiper-step-pagination-title">Unify Corporate - let's work together</span>
              </SwiperSlide>
              <SwiperSlide>
                <span className="swiper-step-pagination-title">How Google Assistant now helps you record stories</span>
              </SwiperSlide>
              <SwiperSlide>
                <span className="swiper-step-pagination-title">Unify Interview with a Lead Designer of the Hubble</span>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      <div className="row py-lg-5">
        <div className="col-lg-6 col-md-8 mx-auto">
          <h1>Hello, unify!</h1>
          <p className="lead text-muted"> Welcome to ismaili.de with Boostrap 5!</p>
          {/* <StaticImage
                            src="../images/gatsby-astronaut.png"
                            width={300}
                            quality={95}
                            formats={["AUTO", "WEBP"]}
                            alt="A Gatsby astronaut"
                            className="img-fluid"
                        /> */}

        </div>
      </div>
      {/*  Card Grid  */}
      <div className="container content-space-1 overflow-hidden">
        {/*  Heading  */}
        <div className="w-lg-65 text-center mx-lg-auto mb-5 mb-sm-7 mb-lg-10">
          <h2>The work we do,<br />and the people we help</h2>
        </div>
        {/*  End Heading  */}

        <div className="row row-cols-1 row-cols-sm-2 gx-7">
          <div className="col mb-5">
            {/*  Card  */}
            <a className="card card-ghost card-transition-zoom h-100" href="../portfolio-case-study.html">
              <div className="card-pinned card-transition-zoom-item">
                <img className="card-img" src="https://placekitten.com/580/480" alt="Image Description" />
                <span className="badge bg-dark text-white card-pinned-top-end">New addition</span>
              </div>

              <div className="card-body">
                <h4>Proof – <span className="fw-medium">Creating a design system for a suite of products</span></h4>
                <p className="card-text">Branding</p>
              </div>
            </a>
            {/*  End Card  */}
          </div>
          {/*  End Col  */}

          <div className="col mb-5">
            {/*  Card  */}
            <a className="card card-ghost card-transition-zoom h-100" href="../portfolio-case-study.html">
              <div className="card-transition-zoom-item">
                <img className="card-img" src="https://placekitten.com/580/481" alt="Image Description" />
              </div>

              <div className="card-body">
                <h4>Front – <span className="fw-medium">Multipurpose Responsive Template</span></h4>
                <p className="card-text">Admin & Dashboard</p>
              </div>
            </a>
            {/*  End Card  */}
          </div>
          {/*  End Col  */}

          <div className="col mb-5">
            {/*  Card  */}
            <a className="card card-ghost card-transition-zoom h-100" href="../portfolio-case-study.html">
              <div className="card-transition-zoom-item">
                <img className="card-img" src="https://placekitten.com/580/482" alt="Image Description" />
              </div>

              <div className="card-body">
                <h4>Front Dashboard – <span className="fw-medium">Admin & Dashboard Template</span></h4>
                <p className="card-text">Landing & Corporate</p>
              </div>
            </a>
            {/*  End Card  */}
          </div>
          {/*  End Col  */}

          <div className="col mb-5">
            {/*  Card  */}
            <a className="card card-ghost card-transition-zoom h-100" href="../portfolio-case-study.html">
              <div className="card-pinned card-transition-zoom-item">
                <img className="card-img" src="https://placekitten.com/580/483" alt="Image Description" />
                <span className="badge bg-dark text-white card-pinned-top-end">New addition</span>
              </div>

              <div className="card-body">
                <h4>Unify – <span className="fw-medium">Multipurpose Template + UI Kit</span></h4>
                <p className="card-text">Admin & Dashboard</p>
              </div>
            </a>
            {/*  End Card  */}
          </div>
          {/*  End Col  */}

          <div className="col mb-5">
            {/*  Card  */}
            <a className="card card-ghost card-transition-zoom h-100" href="../portfolio-case-study.html">
              <div className="card-transition-zoom-item">
                <img className="card-img" src="https://placekitten.com/580/480" alt="Image Description" />
              </div>

              <div className="card-body">
                <h4>Crab – <span className="fw-medium">Web Design & Development</span></h4>
                <p className="card-text">Landing & Corporate</p>
              </div>
            </a>
            {/*  End Card  */}
          </div>
          {/*  End Col  */}

          <div className="col mb-5">
            {/*  Card  */}
            <a className="card card-ghost card-transition-zoom h-100" href="../portfolio-case-study.html">
              <div className="card-transition-zoom-item">
                <img className="card-img" src="https://placekitten.com/580/481" alt="Image Description" />
              </div>

              <div className="card-body">
                <h4>Garvest – <span className="fw-medium">Reinventing customer care for the home</span></h4>
                <p className="card-text">Branding</p>
              </div>
            </a>
            {/*  End Card  */}
          </div>
          {/*  End Col  */}
        </div>
        {/*  End Row  */}

        <div className="text-center">
          <a className="btn btn-white btn-pointer" href="../page-customer-stories.html">View all case studies</a>
        </div>
      </div>
      {/*  End Card Grid  */}
    </Layout >
  )
}

export default IndexPage